import React, { useState, useEffect } from 'react';
import { Icon } from 'semantic-ui-react';

const axios = require('axios').default;

function Contact() {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [reason, setReason] = useState('Order autographed copies');
  const [comments, setComments] = useState('')
  const [address1, setAddress1] = useState('')
  const [address2, setAddress2] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [zip, setZip] = useState('')
  const [country, setCountry] = useState('')
  const [success, setSuccess] = useState(false)
  const [failure, setFailure] = useState(false)
  const [validationError, setValidationError] = useState(false)
  const [validationMessage, setValidationMessage] = useState('')

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('reason')) {
      let searchReason = searchParams.get('reason');
      if (searchReason === 'autographs') {
        setReason('Order autographed copies');
      } else if (searchReason === 'booking') {
        setReason('Booking');
      } else if (searchReason === 'copyright') {
        setReason('Request copyright permission');
      } else if (searchReason === 'theherald') {
        setReason("Order the REGULAR COPY");
      } else if (searchReason === 'theheraldsigned') {
        setReason("Order the EDITOR'S SIGNED COPY");
      } else {
        setReason('Other reason');
      }
    }
  }, [window.location.search]);

  // Email address verification, do not edit.
  // const isEmail = (email) => {
  //   const regex = /^[-_.[:alnum:]]+@((([[:alnum:]]|[[:alnum:]][[:alnum:]-]*[[:alnum:]])\.)+(ad|ae|aero|af|ag|ai|al|am|an|ao|aq|ar|arpa|as|at|au|aw|az|ba|bb|bd|be|bf|bg|bh|bi|biz|bj|bm|bn|bo|br|bs|bt|bv|bw|by|bz|ca|cc|cd|cf|cg|ch|ci|ck|cl|cm|cn|co|com|coop|cr|cs|cu|cv|cx|cy|cz|de|dj|dk|dm|do|dz|ec|edu|ee|eg|eh|er|es|et|eu|fi|fj|fk|fm|fo|fr|ga|gb|gd|ge|gf|gh|gi|gl|gm|gn|gov|gp|gq|gr|gs|gt|gu|gw|gy|hk|hm|hn|hr|ht|hu|id|ie|il|in|info|int|io|iq|ir|is|it|jm|jo|jp|ke|kg|kh|ki|km|kn|kp|kr|kw|ky|kz|la|lb|lc|li|lk|lr|ls|lt|lu|lv|ly|ma|mc|md|me|mg|mh|mil|mk|ml|mm|mn|mo|mp|mq|mr|ms|mt|mu|museum|mv|mw|mx|my|mz|na|name|nc|ne|net|nf|ng|ni|nl|no|np|nr|nt|nu|nz|om|org|pa|pe|pf|pg|ph|pk|pl|pm|pn|pr|pro|ps|pt|pw|py|qa|re|ro|ru|rw|sa|sb|sc|sd|se|sg|sh|si|sj|sk|sl|sm|sn|so|sr|st|su|sv|sy|sz|tc|td|tf|tg|th|tj|tk|tm|tn|to|tp|tr|tt|tv|tw|tz|ua|ug|uk|um|us|uy|uz|va|vc|ve|vg|vi|vn|vu|wf|ws|ye|yt|yu|za|zm|zw)$|(([0-9][0-9]?|[0-1][0-9][0-9]|[2][0-4][0-9]|[2][5][0-5])\.){3}([0-9][0-9]?|[0-1][0-9][0-9]|[2][0-4][0-9]|[2][5][0-5]))$/i;
  //   const isMatch = email.match(regex);
  //   console.log(isMatch)
  //   return(isMatch); // TODO: now returning null
  // }

  // Copied here https://coursesweb.net/javascript/strip_tags-stripslashes-javascript_cs
  // Replaces stripslashes
  const strip_tags = (str, allow) => {
    // making sure the allow arg is a string containing only tags in lowercase (<a><b><c>)
    allow = (((allow || '') + '').toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join('');
   
    var tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;
    var commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;
    return str.replace(commentsAndPhpTags, '').replace(tags, function ($0, $1) {
    return allow.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 :'';
    });
   }

  // Submit form
  let handleSubmit = async (e) => {
    e.preventDefault();

    const isSoyinkaBook = ["Order the REGULAR COPY", "Order the EDITOR'S SIGNED COPY"].includes(reason);

    // Remove success or failure
    setFailure(false);
    setSuccess(false);

    // Add validation
    if(name.trim() === '') {
      setValidationError(true);
      setValidationMessage('Please enter your name.');
      return;
    } else if(email.trim() === '') {
      setValidationError(true);
      setValidationMessage('Please enter your email.');
      return;
    // } else if(!isEmail(email)) {
    //   setValidationError(true);
    //   setValidationMessage('You must enter a valid email address.');
    //   return;
    } else if(phone.trim() === '') {
      setValidationError(true);
      setValidationMessage('Please enter your phone number.');
      return;
    }
    else if(comments.trim() === '') {
      setValidationError(true);
      setValidationMessage('Explain the reason why you are contacting us.');
      return;
    } else if(address1.trim() === '' && isSoyinkaBook) {
      setValidationError(true);
      setValidationMessage('Please enter your street address');
      return;
    } else if(city.trim() === '' && isSoyinkaBook) {
      setValidationError(true);
      setValidationMessage('Please enter your city.');
      return;
    } else if(state.trim() === '' && isSoyinkaBook) {
      setValidationError(true);
      setValidationMessage('Please enter your state.');
      return;
    } else if(zip.trim() === '' && isSoyinkaBook) {
      setValidationError(true);
      setValidationMessage('Please enter your zip code.');
      return;
    }

    // Submit request
    try {
      axios.post('/prod/email', {
        name: name,
        email: email,
        phone: phone,
        reason: reason,
        desc: comments,
        address1: address1,
        address2: address2,
        city: city,
        state: state,
        zip: zip,
        country: country
      })
      .then(function (res) {
        //let resJson = await res.json();
        if (res.status === 200) {
          setName("");
          setEmail("");
          setPhone("");
          setReason("");
          setComments("");
          setAddress1("");
          setAddress2("");
          setCity("");
          setState("");
          setZip("");
          setCountry("");
          setSuccess(true);
        }
      })
      .catch(function (err) {
        setFailure(true);
      });
    } catch (err) {
      setFailure(true);
      console.log(err);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;

    switch(e.target.id) {
      case 'name':
        setName(value)
        break;
      case 'email':
        setEmail(value)
        break;
      case 'phone':
        setPhone(value)
        break;
      case 'reason':
        setReason(value);
        break;
      case 'comments':
        setComments(strip_tags(value, '<b><i><u>'))
        break;
      case 'address1':
        setAddress1(value)
        break;
      case 'address2':
        setAddress2(value)
        break;
      case 'city':
        setCity(value)
        break;
      case 'state':
        setState(value);
        break;
      case 'zip':
        setZip(value)
        break;
      case 'country':
        setCountry(value);
        break;
      default:
        // code block
    }

    // Reset validation messages
    if (success) {
      setSuccess(false);
    }
    if (failure) {
      setFailure(false);
    }
    if (validationError) {
      setValidationError(false);
      setValidationMessage('');
    }
  }

  return (
    <>
    <div className='margin-container'>
        <div style={{
            paddingLeft: '2em',
            paddingRight: '2em',
            paddingTop: '3em',
            paddingBottom: '3em'
        }} className='paper-background'>
            <div className='d-flex justify-content-center mb-3'>
                <h3><b>CONTACT US</b></h3>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6">
                  <div className="content">
                      <form onSubmit={handleSubmit} className="contact-form">
                          <div className="contact-wrapper row">
                            <div className="form-group">
                                <input className="form-control" id="name" name="name" value={name} onChange={(e) => handleChange(e)} placeholder="Name*" type="text" />
                                <span className="alert-error"></span>
                            </div>
                          </div>
                          <div className="contact-wrapper row">
                              <div className="col-lg-6">
                                  <div className="form-group">
                                      <input className="form-control mt-2" id="email" name="email" value={email} onChange={(e) => handleChange(e)} placeholder="Email*" type="email" />
                                      <span className="alert-error"></span>
                                  </div>
                              </div>
                              <div className="col-lg-6">
                                  <div className="form-group mt-2">
                                      <input className="form-control" id="phone" name="phone" value={phone} onChange={(e) => handleChange(e)} placeholder="Phone*" type="text" />
                                      <span className="alert-error"></span>
                                  </div>
                              </div>
                          </div>
                          {/** Reason, Tell more, Address **/}
                          {
                            ["Order the REGULAR COPY", "Order the EDITOR'S SIGNED COPY"].includes(reason)
                            ?
                            <>
                              <div className="contact-wrapper row">
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <select id="reason" name="reason" value={reason} onChange={(e) => handleChange(e)}>
                                          <option value="Order the REGULAR COPY">Order the REGULAR COPY</option>
                                          <option value="Order the EDITOR'S SIGNED COPY">Order the EDITOR'S SIGNED COPY</option>
                                        </select>
                                    </div>
                                </div>
                              </div>
                              <div className="contact-wrapper row">
                                <div className="form-group">
                                    <input className="form-control" id="address1" name="address1" value={address1} onChange={(e) => handleChange(e)} placeholder="Address line 1*" type="text" />
                                    <span className="alert-error"></span>
                                </div>
                              </div>
                              <div className="contact-wrapper row">
                                <div className="form-group">
                                    <input className="form-control" id="address2" name="address2" value={address2} onChange={(e) => handleChange(e)} placeholder="Address line 2" type="text" />
                                    <span className="alert-error"></span>
                                </div>
                              </div>
                              <div className="contact-wrapper row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input className="form-control mt-2" id="city" name="city" value={city} onChange={(e) => handleChange(e)} placeholder="City*" type="text" />
                                        <span className="alert-error"></span>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group mt-2">
                                        <input className="form-control" id="state" name="state" value={state} onChange={(e) => handleChange(e)} placeholder="State*" type="text" />
                                        <span className="alert-error"></span>
                                    </div>
                                </div>
                              </div>
                              <div className="contact-wrapper row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input className="form-control mt-2" id="zip" name="zip" value={zip} onChange={(e) => handleChange(e)} placeholder="Zip code*" type="text" />
                                        <span className="alert-error"></span>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group mt-2">
                                        <input className="form-control" id="country" name="country" value={country} onChange={(e) => handleChange(e)} placeholder="Country" type="text" />
                                        <span className="alert-error"></span>
                                    </div>
                                </div>
                              </div>
                              <div className="contact-wrapper row">
                                  <div className="col-lg-12">
                                      <div className="form-group">
                                        <textarea className="form-control" id="comments" name="comments" value={comments} onChange={(e) => handleChange(e)} placeholder={"Tell us more about your order:*\n\nPlease include the number of copies needed and specify if REGULAR or EDITOR'S SIGNED copies."}></textarea>
                                      </div>
                                  </div>
                              </div>
                            </>
                            :
                            <>
                              <div className="contact-wrapper row">
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <select id="reason" name="reason" value={reason} onChange={(e) => handleChange(e)}>
                                          <option value="Order autographed copies">Order autographed copies</option>
                                          <option value="Booking">Booking</option>
                                          <option value="Request copyright permission">Request copyright permission</option>
                                          <option value="Other reason">Other reason</option>
                                        </select>
                                    </div>
                                </div>
                              </div>
                              <div className="contact-wrapper row">
                                  <div className="col-lg-12">
                                      <div className="form-group">
                                        <textarea className="form-control" id="comments" name="comments" value={comments} onChange={(e) => handleChange(e)} placeholder="Tell us about your questions / requests*"></textarea>
                                      </div>
                                  </div>
                              </div>
                            </>
                          }

                          {/** Send Email and validate **/}
                          <div className="contact-wrapper row">
                              <div className="col-lg-12 d-flex justify-content-center mt-2">
                                  <button className="btn btn-primary" type="submit" name="submit" id="submit">
                                      Send Email <Icon  name='send'/>
                                  </button>
                              </div>
                          </div>

                          <div className="col-lg-12 alert-notification">
                              <div id="message" className="alert-msg"></div>
                          </div>
                          <br />

                          {validationError ?
                          <div className="alert alert-error alert-warning">{validationMessage}</div> : <></>}

                          {success ?
                          <div className='alert alert-success'>
                            <h3>Email sent.</h3>
                            <p>Thanks, your message has been sent.</p>
                          </div> : <></>}

                          {failure ?
                          <div className='alert alert-danger'>
                            <h3>Failed to send</h3>
                            <p>An error occured while sending this message. Please try again later.</p>
                          </div> : <></>}
                      </form>
                  </div>
              </div>
            </div>
        </div>
    </div>
    </>
  );
}

export default Contact;