import React from 'react';
import { Button } from 'semantic-ui-react'

function Works() {

  return (
    <>
        <div className='margin-container'>
            <div style={{
                background: 'snow',
                paddingLeft: '0em',
                paddingRight: '1em',
                paddingTop: '0em',
                paddingBottom: '0em',
                fontSize: '1.25em'
            }}>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="d-flex justify-content-center mt-lg-0 mt-md-4 mb-lg-0 mb-md-4">
                            <img src={require("../img/book-cup.jpeg")} alt="book-front" width={'100%'}/>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className='mt-4' style={{marginLeft: '1em'}}>
                            <p style={{fontSize: '1em'}}><b>Unbreakable Chains</b></p>
                            <p style={{fontSize: '0.80em'}}><b>ISBN: </b>9789956552344</p>
                            <p style={{fontSize: '0.80em'}}><b>Pages: </b>254</p>
                            <p style={{fontSize: '0.80em'}}><b>Published: </b>2022</p>
                            <p style={{fontSize: '0.80em'}}><b>Publisher: </b>Langaa RPCIG</p>
                            <p style={{fontSize: '0.80em'}}><b>Format: </b>Paperback</p>
                            <p style={{fontSize: '0.95em'}} className='mt-3'><b>Now available on all Amazon Regional Hubs</b></p>
                            <Button href='https://www.amazon.com/Unbreakable-Chains-Shaanchuong-mu-Fohnpah/dp/9956552348/' className='mb-3' size='tiny' color='teal' target="_blank">USA</Button>
                            <Button href='https://www.amazon.ca/Unbreakable-Chains-Shaanchuong-Mu-Fohnpah/dp/9956552348/' className='mb-3' size='tiny' color='teal' target="_blank">Canada</Button>
                            <Button href='https://www.amazon.co.uk/Unbreakable-Chains-Shaanchuong-Mu-Fohnpah/dp/9956552348/' className='mb-3' size='tiny' color='teal' target="_blank">UK</Button>
                            <Button href='https://www.amazon.fr/Unbreakable-Chains-Shaanchuong-Mu-Fohnpah/dp/9956552348/' className='mb-3' size='tiny' color='teal' target="_blank">France</Button>
                            <Button href='https://www.amazon.de/-/en/Shaanchuong-Mu-Fohnpah/dp/9956552348/' className='mb-3' size='tiny' color='teal' target="_blank">Germanic Europe</Button>
                            <p style={{fontSize: '0.95em'}}><b>Now available on</b></p>
                            <Button href='https://www.africanbookscollective.com/books/unbreakable-chains' className='mb-4' size='small' color='teal' target="_blank">African Books Collective</Button>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className='mt-4 mb-4' style={{marginLeft: '1em', marginRight: '1em'}}>
                            <h4 className='d-flex justify-content-center mb-4'>Collaboration</h4>
                            <ul>
                                <div className="d-flex justify-content-center mt-lg-0 mt-md-4 mb-lg-0 mb-md-4">
                                    <img src={require("../img/wole-soyinka.jpg")} alt="wole-soyinka-book" width={'100%'}/>
                                </div>
                                <p style={{fontSize: '0.875em', marginTop: '2em'}}>Get <a href='/news' target="_self">WOLE SOYINKA: THE HERALD AT 90</a>!</p>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className='paper-background-box' style={{marginTop: '1em'}}>
                <div className="d-flex justify-content-center">
                    <div className="container-text col-lg-10">
                        <h4>About Unbreakable Chains</h4>
                        <p>In a story of several characters (Cameroonian, Cameroonian-American immigrant,  African American, and European American) who discover, live, and reinvent their personal and collective relations vis-à-vis Africa and its realities. . This story of Africanity and humanity is told with scientific diction and a dose of African science fiction. It is founded on the quest and sense of freedom from nature and nurture. The idea of absolute freedom warrants the freedom to define freedom; so long as one's freedom is predefined, one can never claim to be free. Yet, the previous sentence already confines absolute freedom to a certain meaning. Freedom is an ideal; we are all captives going from one prison cell to another. Mercy Lewis, a descendant of enslaved free men and free women is nothing else but a slave to her African heritage, her past, and all the stereotypes associated with her personality. We all are Mercys to a certain extent.</p>
                    </div>
                </div>
                
            </div>
        </div>
    </>
  );
}

export default Works;