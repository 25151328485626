import React from 'react';
import { Icon } from 'semantic-ui-react';

function Footer() {
  return (
    <footer className="footer text-light">
      <div className="container">
          <div className="default-padding">
              <div className="row">
                  <div className="single-item col-lg-4 col-md-6 item mt-2 mb-2">
                      <div className="link">
                          <h4 className="footer-title">Author</h4>
                          <div className="container-text d-flex justify-content-center" style={{display: 'flex'}}>
                            <a href='/biography'><img src={require("../img/author-picture.JPG")} className="logo" alt="Logo" width={'60%'}/></a>
                          </div>
                      </div>
                  </div>
                  <div className="single-item col-lg-4 col-md-6 item mt-2 mb-2">
                      <div>
                          <h4 className="footer-title">Social</h4>
                          <div>
                              <p><Icon name='facebook' size='large' color='blue'/><a href="https://www.facebook.com/profile.php?id=100087432902010" target="_blank" rel="noopener noreferrer">Shaanchuong Mu-Fohnpah</a></p>
                              <p><Icon name='youtube' size='large' color='red'/><a href="https://www.youtube.com/channel/UC7c7f978_AQl1XRa55pIIvA" target="_blank" rel="noopener noreferrer">@ShaanchuongMuFohnpah</a></p>
                              <p><Icon name='instagram' size='large' color='pink'/><a href="https://www.instagram.com/shaanchuong/" target="_blank" rel="noopener noreferrer">@shaanchuong</a></p>
                              <p><Icon name='whatsapp' size='large' color='green'/><a href="https://api.whatsapp.com/send/?phone=237692194388" target="_blank" rel="noopener noreferrer">(+237) 692 19 43 88</a></p>
                          </div>
                          <h4 className="footer-title mt-4">Links</h4>
                          <div>
                              <p><a href='https://fohnpah.blogspot.com/' target="_blank" rel="noopener noreferrer">Check out my blog</a></p>
                              <p><a href='/contact?reason=autographs'>Order autographed copies</a></p>
                          </div>
                      </div>
                  </div>

                  <div className="single-item col-lg-4 col-md-6 item mt-2 mb-2">
                      <div className="link">
                          <h4 className="footer-title">Copyright</h4>
                          <p><b>© 2024 fohnpah.com. All Rights Reserved.</b></p>
                            You may not copy, reproduce, distribute, publish, display, perform, modify, create derivative works, transmit, or in any way exploit any such content, nor may you distribute any part of this content over any network, including a local area network, sell or offer it for sale, or use such content to construct any kind of database. You may not alter or remove any copyright or other notice from copies of the content on fohnpah.com without prior written permission. For permission to use the content on fohnpah.com, please submit a request <a href='/contact?reason=copyright'>here</a>.
                      </div>
                  </div>

              </div>
          </div>
      </div>

      <div className="footer-bottom">
          <div className="container">
              <div className="row align-center">
                      <p className='mt-4 mb-4'>Designed by Viadsys, LLC</p>
                  {/* <div className="col-lg-6 text-right link">
                      <ul>
                          <li>
                              <a href="#">Terms</a>
                          </li>
                          <li>
                              <a href="#">Privacy</a>
                          </li>
                          <li>
                              <a href="#">Support</a>
                          </li>
                      </ul>
                  </div> */}
              </div>
          </div>
      </div>
    </footer>
  );
}

export default Footer;