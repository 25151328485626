import React, {useState} from 'react';
import { Accordion, Icon } from 'semantic-ui-react';

function Faq() {
    const [activeIndex, setActiveIndex] = useState(0);
    const handleClick = (e, titleProps) => {
        const { index } = titleProps
        const newIndex = activeIndex === index ? -1 : index
        setActiveIndex(newIndex)
    }

  return (
    <div className='margin-container'>
        <div className='paper-background'>
            <div className='d-flex justify-content-center mb-3'>
                <h3><b>FAQ</b></h3>
            </div>
            <div className='faq-container'>
                <Accordion fluid styled>
                    <Accordion.Title
                      active={activeIndex === 0}
                      index={0}
                      onClick={handleClick}
                    >
                      <Icon name='dropdown' />
                      WHAT INSPIRED YOU TO BECOME A WRITER?
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 0}>
                      <p>
                      I grew up fascinated by storytellers and novels, and my bubbling creative mind needed an outlet and so I headed for the nearest exit which for me is writing.
                      </p>
                    </Accordion.Content>
                    <Accordion.Title
                      active={activeIndex === 1}
                      index={1}
                      onClick={handleClick}
                    >
                      <Icon name='dropdown' />
                      HOW DO YOU CRAM CREATIVITY AND REALISM INTO ONE?
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 1}>
                      <p>
                      I go with creativity first, and as I review the work I try to add details that bear the component of realism.
                      </p>
                    </Accordion.Content>
                    <Accordion.Title
                      active={activeIndex === 2}
                      index={2}
                      onClick={handleClick}
                    >
                      <Icon name='dropdown' />
                      DO YOU PLAN TO WRITE OTHER BOOKS?
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 2}>
                      <p>
                      Not plan! It comes on its own. But rather, I hope to write other books, or better still to complete those I have in my drawers.
                      </p>
                    </Accordion.Content>
                    <Accordion.Title
                      active={activeIndex === 3}
                      index={3}
                      onClick={handleClick}
                    >
                      <Icon name='dropdown' />
                      WILL YOUR BOOKS BE MADE INTO MOVIES OR TV SERIES?
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 3}>
                      <p>
                      I am not opposed to it. Ideally, I'll like to have a say in interpreting certain scenes, simply because I feel as though writing did not capture all of my intentions and imagination.
                      </p>
                    </Accordion.Content>
                    <Accordion.Title
                      active={activeIndex === 4}
                      index={4}
                      onClick={handleClick}
                    >
                      <Icon name='dropdown' />
                      WHERE DO YOU COME UP WITH YOUR IDEAS? WHAT STEPS DO YOU TAKE TO LAUNCH AN IDEA AND MAKE A NOVEL OUT OF IT?
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 4}>
                      <p>
                      My source is "The Source",  I am just a vessel. So when the thought comes to my mind I start and see if it flows naturally or is not mature enough to flow naturally. If it flows naturally, no matter the flow rate, I embark on the writing process immediately.
                      </p>
                    </Accordion.Content>
                    <Accordion.Title
                      active={activeIndex === 5}
                      index={5}
                      onClick={handleClick}
                    >
                      <Icon name='dropdown' />
                      HOW LONG DOES IT USUALLY TAKE YOU TO WRITE A FULL-LENGTH (80,000+ WORDS) NOVEL?
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 5}>
                      <p>
                      I am very scattered and tend to write multiple books and undertake multiple scientific projects at the same time, so I cannot for sure tell you how much time the actual write process takes me. But I believe if allowed to do nothing but write, I could take a month to finish a Novel's first draft.
                      </p>
                    </Accordion.Content>
                    <Accordion.Title
                      active={activeIndex === 6}
                      index={6}
                      onClick={handleClick}
                    >
                      <Icon name='dropdown' />
                      MY BOOK CLUB IS READING ONE OF YOUR BOOKS. WILL YOU VISIT, SKYPE, OR CALL TO DISCUSS YOUR BOOK WITH US?
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 6}>
                      <p>
                      I am open to all such opportunities, if we find a time and process that works for us both then it's a deal.
                      </p>
                    </Accordion.Content>
                    <Accordion.Title
                      active={activeIndex === 7}
                      index={7}
                      onClick={handleClick}
                    >
                      <Icon name='dropdown' />
                      I AM A BOOK BLOGGER AND WOULD LIKE TO REVIEW ONE OF YOUR BOOKS. CAN I GET AN ARC, REVIEW COPY, OR REQUEST AN INTERVIEW?
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 7}>
                      <p>
                      For sure. I'll propose you contact my publisher (Langaa Research and Publishing) and we kick it from there.
                      </p>
                    </Accordion.Content>
                    <Accordion.Title
                      active={activeIndex === 8}
                      index={8}
                      onClick={handleClick}
                    >
                      <Icon name='dropdown' />
                      WOULD YOU BE INTERESTED IN DONATING MONEY OR A SIGNED BOOK TO MY CHARITY?
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 8}>
                      <p>
                      With the boundary between personal opinions and dogma being very thin, I usually like to be anonymous in my engagement and be mindful of my diverse audience. As such I prefer to personally approach organizations myself after knowing all I need to know about their initiative.
                      </p>
                    </Accordion.Content>
                    <Accordion.Title
                      active={activeIndex === 9}
                      index={9}
                      onClick={handleClick}
                    >
                      <Icon name='dropdown' />
                      HOW CAN I GET A PERSONALIZED AND / OR SIGNED COPY OF YOUR BOOK(S)?
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 9}>
                      <p>
                      Meet up with me anywhere and we get that baby marked. My website shows my events and I'll be glad to meet my readers and sign their copies.
                      </p>
                    </Accordion.Content>
                    <Accordion.Title
                      active={activeIndex === 10}
                      index={10}
                      onClick={handleClick}
                    >
                      <Icon name='dropdown' />
                      WOULD YOU ATTEND AND / OR SPEAK AT MY EVENT?
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 10}>
                      <p>
                      Contact me with ample information and we will see how we can make it happen.
                      </p>
                    </Accordion.Content>
                    <Accordion.Title
                      active={activeIndex === 11}
                      index={11}
                      onClick={handleClick}
                    >
                      <Icon name='dropdown' />
                      WHERE CAN I GET THE BOOK?
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 11}>
                      <p>
                      The book is available on your Regional Amazon Hub. 
                      </p>
                    </Accordion.Content>
                </Accordion>
            </div>
        </div>
    </div>
  );
}

export default Faq;