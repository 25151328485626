import React from 'react';
import './App.css';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Home from './components/Home';
import NavBar from './components/NavBar';
import NotFound from './components/NotFound';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Biography from './components/Biography';
import Works from './components/Works';
import Faq from './components/Faq';
import News from './components/News';

function App() {
  return (
    <Router>
      <div className='wood-background'>
        <NavBar />
        <Routes>
          <Route exact path="/" element={<Home />}/>
          <Route path="/biography" element={<Biography />}/>
          <Route path="/works" element={<Works />}/>
          <Route path="/news" element={<News />}/>
          <Route path="/faq" element={<Faq />}/>
          <Route path="/contact" element={<Contact />}/>
          <Route path="*" element={<NotFound />}/>
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
