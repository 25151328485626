import React from 'react';

function Biography() {

  return (
    <div className='margin-container'>
        <div style={{
            paddingLeft: '2em',
            paddingRight: '2em',
            paddingTop: '3em',
            paddingBottom: '3em'
        }} className='paper-background'>
            <div className='d-flex justify-content-center mb-3'>
                <h3><b>BIOGRAPHY</b></h3>
            </div>
            <div className="row container-text">
                <div className="col-lg-6" style={{padding: '2em'}}>
                    <img src={require("../img/author-picture.JPG")} className="logo" alt="Logo" width={'75%'}/>
                </div>
                <div className="col-lg-6" style={{padding: '2em'}}>
                    <p>Shaanchuong mu-Fohnpah is a Bantu-American, born and bred in the plains of the Nun Valley located in present-day nation of Cameroon. He is the youngest of a very big family in which both parents were educators and storytellers. His mother, a great folk tales’ narrator, his father, a magical recounter of personal and historical anecdotes, nursed in him the love for storytelling. His oldest memories are those of flipping through storybooks and  squatting beside a lantern for family story time.</p>
                </div>
            </div>
            <div className="row flex-column-reverse flex-lg-row container-text">
                <div className="col-lg-6" style={{padding: '2em'}}>
                    <p>His attachment to the magic of words will take hit when he is confronted by the choice to pursing secondary studies in either Arts  or Sciences but not a combination. A tough choice; at the end he picks Sciences and consecrates some of his free extracurricular time to reading and poetry writing. In line with this choice he studies  Physics at the University right up to sophomore level. In the course of this sophomore year Shaanchuong mu-Fohnpah immigrated to the United States of America. The inclusive pedagogical system of American universities allowed him to reconnect with college level writing while studying Electrical Engineering at Prince George's Community College--Largo and University of Maryland—College Park.</p>
                </div>
                <div className="col-lg-6" style={{padding: '2em'}}>
                    <img src={require("../img/kid-picture.JPG")} className="logo" alt="Logo" width={'60%'}/>
                </div>
            </div>
            <div className="row container-text">
                <div className="col-lg-6" style={{padding: '2em'}}>
                    <img src={require("../img/family-picture.jpeg")} className="logo" alt="Logo" width={'100%'}/>
                </div>
                <div className="col-lg-6" style={{padding: '2em'}}>
                   <p>Amidst a professional growth spanning over a decade of professional experience in Systems Engineering and Electrical Engineering, and as an Adjunct Faculty at Gulf Coast State College for close to 8 years, Shaanchuong mu-Fohnpah also earned a master’s degree in electrical engineering, another master’s degree in systems engineering, and is currently pursing a PhD in Systems Engineering at George Washington University. He is happily settled in Panama City with his lovely and supportive wife and children. Galvanized by the advent of their children into their lives and the need to pass onto them the beauty of their African heritage, he has embarked on his journey of a published storyteller, writer, and poet. This also fulfills the author’s desire to reveal a secret facet of himself.  He designs his stories using notions in literature, engineering, and sciences as modules of a <i>literaro-engineering</i> project. To him this journey is a way to reconcile his heritage and accomplishment of an Engineering professional, a literature lover, a member of a royal lineage, a family man, a pan-Africanist, and an African immigrant.</p> 
                </div>
            </div>
            
        </div>
    </div>
  );
}

export default Biography;