import React from 'react';
import { Icon } from 'semantic-ui-react';
import {useLocation} from 'react-router-dom';

function NavBar(props) {
  const location = useLocation();

  const returnActiveClassByPath = path => {
    if (location.pathname === path || location.pathname === (path + '/')) {
      return 'active';
    } else {
      return null;
    }
  }

  /* Toggle between adding and removing the "responsive" class to topnav when the user clicks on the icon */
  const toggleResponsive = () => {
    var x = document.getElementById("myTopnav");
    if (x.className === "topnav") {
      x.className += " responsive";
    } else {
      x.className = "topnav";
    }
  }

  return (
    <div className="topnav" id="myTopnav">
        <div>
            <a href="/" className={returnActiveClassByPath('/')}>Home</a>
            <a href="biography" className={returnActiveClassByPath('/biography')}>Biography</a>
            <a href="works" className={returnActiveClassByPath('/works')}>Works</a>
            <a href="news" className={returnActiveClassByPath('/news')}>News</a>
            <a href="faq" className={returnActiveClassByPath('/faq')}>FAQ</a>
            <a href="contact" className={returnActiveClassByPath('/contact')}>Contact Us</a>
            <a className="icon" onClick={() => toggleResponsive()}>
                <Icon name='bars' />
            </a>
        </div>
    </div>
  );
}

export default NavBar;